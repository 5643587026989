// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './components/About';
import Research from './components/Research';
import Products from './components/Products';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './styles.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <main className="main-content container">
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/about" element={<About />} />
            <Route path="/research" element={<Research />} />
            <Route path="/products" element={<Products />} />
          </Routes>
        </main>
        <Footer /> {/* Add the footer here */}
      </div>
    </Router>
  );
}

export default App;
