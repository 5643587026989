// src/components/Products.js
import React, { useState } from 'react';
import BookCallButton from '../components/BookCallButton';
import Typewriter from './Typewriter';



function Products() {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const products = [
    {
      id: 1,
      customer: 'Startups',
      name: 'Mesh',
      description: 'Craft the perfect prompt.',
      details: 'Mesh enables you to hone in on the right prompt quickly, by letting anyone on your team provide feedback on the outputs. It automatically updates your prompts, re-generates and evaluates the responses based on the feedback, until it gets it right. It is a bridge between your technical and non-technical staff.',
      feature1:`Understand and steer AI outputs for greater control`, 
      feature2:`Debug and fine-tune responses for optimal performance`,
      feature3:`Accelerate innovation with tools that simplify prompt engineering and improve AI-guided solutions`,
    },
    {
      id: 2,
      customer: 'Government',
      name: 'Navigator',
      description: 'Understand your LLMs.',
      details: 'Navigator equips organizations with the tools to monitor and steer AI systems effectively at scale. With advanced interpretability.',
      feature1:`Gain deep insights into AI decision-making processes`, 
      feature2:`Ensure AI reliability and trustworthiness through transparency`,
      feature3:`Implement robust controls for tailored standards,  governance and regulations`,
    },
    {
      id: 3,
      customer: 'Enterprise',
      name: 'Manager',
      description: 'Monitor your AI Employees in real-time.',
      details: 'Manager is a comprehensive tool for the evaluation and monitoring of LLM agents, offering valuable insights and enabling precise adjustments. Drawing on the advanced techniques developed for Mesh and Navigator.',
      feature1:`Continuously assess and track AI behavior to ensure reliability and trustworthiness`, 
      feature2:`Make informed adjustments to AI outputs to optimize accuracy and performance`,
      feature3:`Leverage insights from Mesh's prompt refinement and Navigator's interpretability to fine-tune agent behavior at scale`,
    },
  ];

  const handleClick = (productId) => {
    setSelectedProduct(selectedProduct === productId ? null : productId);
  };

  return (
    <div className="products-page">
            <h1>        <Typewriter
          text="Our Products"
          speed={60} 
        /></h1>
      <div className="product-grid">
        {products.map((product) => (
          <div
            key={product.id}
            className={`product ${selectedProduct === product.id ? 'selected' : ''}`}
            onClick={() => handleClick(product.id)}
          >
           <h3> {product.customer}: <span className="bold-text">{product.name}</span></h3>

            <p>{product.description}</p>
          </div>
        ))}
      </div>
      {selectedProduct && (
        <div className="product-details">
          <h3><span className="bold-text">{products.find((product) => product.id === selectedProduct).name} </span></h3>
          <p>{products.find((product) => product.id === selectedProduct).details}</p>
          <ul>
              <li>{products.find((product) => product.id === selectedProduct).feature1}</li>
              <li>{products.find((product) => product.id === selectedProduct).feature2}</li>
              <li>{products.find((product) => product.id === selectedProduct).feature3}</li>
          </ul>
        </div>
      )}
      <div className="book-call-container">
      <BookCallButton />
      </div>
      <section className="contact-section">
        <h2>Support</h2>
        <p>
         If you have any questions about any of your Tangentic products, please reach out at <a href="mailto:info@tangentic.ai">info@tangentic.ai</a> or email your Tangentic agent directly.
        </p>
      </section>
    </div>
  );
}

export default Products;
