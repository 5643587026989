// src/components/Navbar.js
import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = (event) => {
    event.stopPropagation(); // Prevent triggering handleClickOutside when clicking the button
    setIsOpen((prevIsOpen) => !prevIsOpen);
    event.currentTarget.blur(); // Remove focus from the button
  };
  

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false); // Close menu if clicking outside
    }
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close menu when a link is clicked
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);  

  return (
    <header className="navbar">
      <div className="container">
        <div className="logo-container">
          <img src={`${process.env.PUBLIC_URL}/icon.svg`} alt="Tangentic Icon" className="tangentic-icon" />
          <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Tangentic Logo" className="logo" />
        </div>
        <button className="hamburger" onClick={toggleMenu}>
          ☰
        </button>
        <nav ref={menuRef} className={`navbar-menu ${isOpen ? 'open' : ''}`}>
          <NavLink to="/about" className="navbar-link" activeClassName="active" onClick={handleLinkClick}>About</NavLink>
          <NavLink to="/products" className="navbar-link" activeClassName="active" onClick={handleLinkClick}>Products</NavLink>
          <NavLink to="/research" className="navbar-link" activeClassName="active" onClick={handleLinkClick}>Research</NavLink>
        </nav>
      </div>
    </header>
  );
}

export default Navbar;